<template>
  <div class="iq-top-navbar bg-primary mr-0 pr-0">
    <div class="iq-navbar-custom mr-0 pr-0">
      <nav
        class="navbar navbar-expand-lg py-0 p-0 mr-0 d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-left pl-0 ml-0">
          <i
            v-b-tooltip.hover
            title="Menu de aplicaciones"
            class="fas fa-bars py-2 mt-1 pointer mr-1"
            @click="colapsarPanelIzquierdo()"
            style="color: white; font-size: 1.8rem"
          ></i>
          <router-link
            to="/dashboard"
            class="header-logo d-flex align-items-left responsive-xl-md"
          >
            <img
              src="/img/HXLogoWhite.png"
              class="img-fluid rounded-normal py-2"
              alt="logo"
              style="width: 9rem; display: flex !important"
            />
          </router-link>
        </div>
        <!-- <div class="">

          <router-link to="/dashboard" class="header-logo d-flex align-items-left ">
            <img src="/img/HXLogoWhite.png" class="img-fluid rounded-normal py-2" alt="logo" style="width: 10rem; display: flex !important" />
          </router-link>
        </div> -->
        <div
          class="ml-lg-auto mr-0 pr-0 d-flex align-items-center movil-navbar-item"
        >
          <!-- <li v-b-tooltip.hover :title="`ADN MAPA CONDUCTUAL`" class="caption-content mr-3 position-relative" v-sidebar-toggle @click="$bvModal.show('modalMapaConductual')">
            <i class="fas fa-regular fa-map zoom text-white" style="font-size: 1.8rem"></i>
          </li>
          <modalMapaConductual_component /> -->

          <li
            v-b-tooltip.hover
            :title="`Bienestar - ${totalCoins} coins`"
            class="mr-3 position-relative"
            v-sidebar-toggle
            @click="$router.push('/helex/canjes')"
          >
            <i
              class="fas fa-face-smile zoom text-white"
              style="font-size: 1.8rem"
            ></i>

            <vs-button
              circle
              relief
              danger
              v-if="!totalCoins > 0"
              class="position-absolute"
              style="
                left: 18px !important;
                top: -10px;
                width: 1rem;
                height: 1rem;
              "
            >
              {{ formatNumber(totalCoins) }}
            </vs-button>
          </li>
          <!-- Other elements aligned to the right -->
          <div class="position-relative mr-3">
            <div @click="$router.push('/helex/Tickets')">
              <i
                v-b-tooltip.hover
                :title="`Servicio de ayuda - ${listaTickets.length} tickets`"
                class="fas fa-regular fa-headset zoom"
                style="font-size: 1.8rem; color: white"
              ></i>
              <vs-button
                circle
                relief
                danger
                v-if="listaTickets.length > 0"
                class="position-absolute"
                style="
                  left: 18px !important;
                  top: -10px;
                  width: 1rem;
                  height: 1rem;
                "
              >
                {{ formatNumber(listaTickets.length) }}
              </vs-button>
            </div>
          </div>
          <li class="caption-content pl-2" v-sidebar-toggle>
            <span v-b-toggle.sidebar-right>
              <img
                v-b-tooltip.hover
                title="Menu de categorias"
                src="../../../../assets/images/Menu-hamburguesa.png"
                class="img-fluid zoom pr-0 mr-0"
                style="width: 50%; margin-left: -10px; margin-right: -30px"
                alt=""
              />
            </span>
          </li>
          <!-- <li class="caption-content" v-sidebar-toggle>
            <span @click="showModal()">
              <img src="../../../../assets/images/Menu-hamburguesa.png" class="img-fluid zoom pr-0 mr-0"
                style="max-width: 50%; margin-left: -20px; margin-right: -15px;" alt="" />
            </span>
          </li> -->
          <span class="caption-conten" v-sidebar-toggle @click="cerrarSesion()">
            <span>
              <img
                v-b-tooltip.hover
                title="Cerrar sesión"
                src="../../../../assets/images/boton-on-off-l.png"
                class="img-fluid zoom pr-0 mr-0"
                style="width: 70%; margin-left: -25px"
                alt=""
              />
            </span>
          </span>
        </div>
      </nav>
    </div>
    <modal />
    <b-sidebar
      id="sidebar-right"
      backdrop-variant="transparent"
      backdrop
      style="background-color: white !important"
      class=""
      right
      shadow
      no-header
    >
      <template #default="{ hide }">
        <div class="d-flex">
          <b-button
            size="sm"
            variant="transparent"
            class="mr-1 ml-2"
            style="border-radius: 0px; max-height: 1.7rem; margin-top: 15px"
            @click="hide"
            ><i class="fa-solid fa-xmark m-0 p-0" style="font-size: 1.5rem"> </i
          ></b-button>
          <h4 class="mb-3">
            <b
              ><i
                class="fa-solid fa-folder-open pr-1 text-primary pt-4 ml-2"
              ></i>
              CATEGORIAS
            </b>
          </h4>
        </div>

        <div @click="enviarSeccion(0)" class="mt-1">
          <sidebarGroup
            :dataGroupV2="dataGroupV2.registroJson?.data || []"
          ></sidebarGroup>
        </div>
        <!-- <div @click="enviarSeccion(1)">
          <sidebarGroup :dataGroupV2="dataGroupV2.data.filter(x => x.groupId > 2 && x.groupId < 6)"></sidebarGroup>
        </div> -->
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import { eventBus } from "../../../../main";
import { core } from "../../../../config/pluginInit";

import sidebarGroup from "@/components/helexium/sidebarGroup.vue";
// import dataGroupV2 from "./groupsV2.json";
// import Fullscreen from "../../../fullscreen/Fullscreen";
import sideBarItem from "@/JsonData/header.json";
// import modal from "../../../helexium/modalHome.vue";
import dataGroup from "./groups.json";
import modal from "../../../helexium/modalHome.vue";
import { mapGetters, mapMutations } from "vuex";
// import modalMapaConductual_component from "./../../../helexium/modalMapaConductual_component.vue";

// import Fullscreen    from '../../../fullscreen/Fullscreen'
export default {
  name: "HeaderStyle9",
  components: {
    modal,
    sidebarGroup,
    //,   modalMapaConductual_component
  },

  data() {
    return {
      // subGroup:subGroup,
      itemGroupGen: [],
      itemSeleccionado: null,
      dataGroupV2: "",
      sidebarItems: sideBarItem,
      sidebarGroupTitle: true,
      dataGroup: dataGroup,
      group: 0,
      dataGroupFiltered: {},
      usuario: {},
      listaTickets: [],
      dark: null,
      progresoHojaDeVida: 0,
      personaUbicacion: {
        area: {
          id: null,
        },
      },
      listRangosEvaluaciones: {
        empresaId: null,
        nombre_empresa: null,
        evaluadoId: null,
        nombreEvaluado: null,
        r_50_59: 0,
        r_60_74: 0,
        r_75_84: 0,
        r_85_94: 0,
        r_95_100: 0,
      },
      listCanjes: [
        {
          id: null,
          beneficioId: null,
          beneficio: null,
          personaId: null,
          areaId: null,
          categoria: null,
          coins: null,
          fecha: null,
        },
      ],
      empresa: {},
      listTask: [
        {
          id: null,
          collaboratorId: null,
          collaborator: null,
          personaId: null,
          legoCardId: null,
          legoCard: {
            effort: 0,
          },
          points: null,
          acceptTask: null,
        },
      ],
      colapsado: false,
    };
  },
  async mounted() {
    let { empresa } = JSON.parse(localStorage.setPersonaAct);
    this.empresa = empresa;
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));

    this.listCanjes = [];
    this.listTask = [];
    await this.getProgresoHojaDeVida(this.usuario.id);
    await this.getPersonaUbicacion(this.usuario.id);
    await this.getConfigCoinsCanjesByEmpresa(
      this.usuario.empresa.id,
      this.personaUbicacion.area.id
    );
    await this.getCanjesLog();
    await this.getListRangosEvaluacionesPersona();
    await this.getListLegoAssignmentResponsibilityByPersona();
    await this.fetchJsonData();

    this.handleCoins();

    // console.log(this.totalCoins);
    // console.log(this.configCoinsCanjes);
    await this.getData();
    this.$store.watch(
      (state) => [state.group, state.newChat].join(),
      () => {
        //console('***************************',this.$store.state.group)
        this.dataGroupFiltered =
          this.dataGroup.data[this.$store.state.group - 1]; // this.dataGroup.data[this.group];
        // this.modalShowLocal = this.$store.state.modalShow;
        //console("actualizando modalshowlocal en modalhome", this.dataGroup);
        // console(
        //   "actualizando modalshowlocal en modalhome",
        //   this.dataGroupFiltered
        // );
        this.getData();
      }
    );

    this.dataGroupFiltered =
      this.dataGroup.data[this.$store.state.group - 1] ||
      this.dataGroup.data[localStorage.getItem("group") - 1]; // this.dataGroup.data[this.group];
    // console("datagroup ", this.dataGroupFiltered);
    // alert(this.dark)

    core.changesidebar();
  },
  //  components:{Fullscreen},
  computed: {
    ...mapGetters({
      appName: "appName",
      logo: "logo",
      name: "name",
      image1: "image1",
      getTotalCanjes: "getTotalCanjes",
      getTotalCoins: "getTotalCoins",
      getIsAvatar: "getIsAvatar",
      getIsOnboarding: "getIsOnboarding",
      getGananciasCoinsTasks: "getGananciasCoinsTasks",
      getGananciasCoinsRangoEvaluaciones: "getGananciasCoinsRangoEvaluaciones",
      getConfigCoinsCanjes: "getConfigCoinsCanjes",
    }),
    configCoinsCanjes() {
      return this.getConfigCoinsCanjes;
    },
    totalCoins() {
      return this.getTotalCoins;
    },
    totalCanjes() {
      return this.getTotalCanjes;
    },
    isOnboarding() {
      return this.getIsOnboarding;
    },
    isAvatar() {
      return this.getIsAvatar;
    },
    tasksCoins() {
      return this.getGananciasCoinsTasks;
    },
    rangoEvaluacionesCoins() {
      return this.getGananciasCoinsRangoEvaluaciones;
    },
  },
  methods: {
    cerrarSesion() {
      localStorage.removeItem("tk");
      localStorage.removeItem("credenciales");
      localStorage.removeItem("user");
      this.$router.push("/");
    },

    async fetchJsonData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RegistrosJsonControlAccesos/GetLatestJson",
        });
        if (res && res.registroJson) {
          try {
            let cleanedJsonString = res.registroJson
              .replace(/\\"/g, '"')
              .replace(/\\u([0-9a-fA-F]{4})/g, "\\u$1");
            const parsedJson = JSON.parse(cleanedJsonString);
            //console.log("ESTE ES EL REGISTROOOOOOOOOOOOOOOOO" + parsedJson)
            this.dataGroupV2 = {
              id: res.id,
              fechaRegistro: res.fechaRegistro,
              registroJson: parsedJson,
            };
          } catch (parseError) {
            this.dataGroupV2 = null;
          }
        } else {
          this.dataGroupV2 = null;
        }
      } catch (error) {
        this.dataGroupV2 = null;
      }
    },

    formatNumber(value) {
      if (value >= 10) {
        return "9+";
      }
      return value;
    },
    colapsarPanelIzquierdo() {
      // this.colapsado = !this.colapsado
      // console.log("colapsando en header");
      eventBus.$emit("colapsando", true);
    },
    // enviarSeccion(id){
    // console.log('enviarseccion', id)
    // eventBus.$emit("seccionSeleccionada", id);
    // },
    enviarSeccion(id) {
      // console.log("enviarseccion", id);
      eventBus.$emit("seccionSeleccionada", id);
    },
    ...mapMutations({
      setTotalCoins: "setTotalCoins",
      setTotalCanjes: "setTotalCanjes",
      setIsAvatar: "setIsAvatar",
      setIsOnboarding: "setIsOnboarding",
      setIsCurriculum: "setIsCurriculum",
      setGananciasCoinsTasks: "setGananciasCoinsTasks",
      setGananciasCoinsRangoEvaluaciones: "setGananciasCoinsRangoEvaluaciones",
      setConfigCoinsCanjes: "setConfigCoinsCanjes",
    }),
    updateConfigCoinsCanjes(newValue) {
      this.setConfigCoinsCanjes(newValue);
    },
    updateTotalCoins(newValue) {
      this.setTotalCoins(newValue);
    },
    updateTotalCanjes(newValue) {
      this.setTotalCanjes(newValue);
    },
    updateIsAvatar(newValue) {
      this.setIsAvatar(newValue);
    },
    updateIsOnboarding(newValue) {
      this.setIsOnboarding(newValue);
    },
    updateIsCurriculum(newValue) {
      this.setIsCurriculum(newValue);
    },
    updateTasksCoins(newValue) {
      this.setGananciasCoinsTasks(newValue);
    },
    updateRangoEvaluacionesCoins(newValue) {
      this.setGananciasCoinsRangoEvaluaciones(newValue);
    },
    handleCoins() {
      let canjes = this.listCanjes.reduce(
        (TotalCanje, canje) => TotalCanje + parseInt(canje.coins),
        0
      );
      this.updateTotalCanjes(canjes);

      this.updateTotalCoins(this.handleGanancias() - canjes);
    },
    handleGanancias() {
      let total = 0;

      // avatar
      // console.log("avatar", this.usuario.sharedCoins);

      if (this.usuario.sharedCoins !== null) {
        total += this.usuario.sharedCoins;
      }

      // avatar
      if (this.usuario.avatar !== null && this.usuario.stringAvatar !== null) {
        total += this.configCoinsCanjes.avatar;
        this.updateIsAvatar(true);
      }
      // onboarding
      if (this.isOnboarding) {
        total += this.configCoinsCanjes.onboarding;
        // this.updateIsOnboarding(true);
      }

      // curriculum
      if (this.progresoHojaDeVida === 100) {
        total += this.configCoinsCanjes.curriculum;
        this.updateIsCurriculum(true);
      }

      // tareas
      if (this.listTask.length > 0) {
        // reset data tasks
        this.updateTasksCoins({
          basico: {
            completed: 0,
            pendients: 0,
          },
          medio: {
            completed: 0,
            pendients: 0,
          },
          alto: {
            completed: 0,
            pendients: 0,
          },
        });

        let { basico, medio, alto } = this.tasksCoins;

        // console.log(this.listTask);

        // primos para estimar esfuerzo
        // [0, 1,2,3,5,7,11,13,17,19,23,29,31,37,41,43,47],

        this.listTask.forEach((task) => {
          let taskLevel;
          // eslint-disable-next-line no-unused-vars
          let pointsMultiplier;

          // Determinar el nivel de la tarea
          if (task.legoCard.effort >= 31) {
            taskLevel = alto;
            pointsMultiplier = this.configCoinsCanjes.tareaAlta;
          } else if (task.legoCard.effort >= 11 && task.legoCard.effort < 31) {
            taskLevel = medio;
            pointsMultiplier = this.configCoinsCanjes.tareaMedia;
          } else {
            taskLevel = basico;
            pointsMultiplier = this.configCoinsCanjes.tareaBasica;
          }

          // Incrementar completadas o pendientes según corresponda
          if (task.acceptTask !== null) {
            taskLevel.completed++;
            // Calcular puntos y actualizar total
            if (task.points === 0) {
              total += pointsMultiplier;
            } else if (task.points > 0) {
              total += pointsMultiplier * task.points + pointsMultiplier;
            } else {
              total -= Math.abs(task.points);
            }
          } else {
            taskLevel.pendients++;
          }
        });

        const newListTask = {
          basico,
          medio,
          alto,
        };

        // console.log(basico);

        // console.log("newListTask---------->", medio);

        this.updateTasksCoins(newListTask);
      }
      // evaluaciones

      if (this.listRangosEvaluaciones.empresaId !== null) {
        const { r_50_59, r_60_74, r_75_84, r_85_94, r_95_100 } =
          this.listRangosEvaluaciones;

        let newDataRanges = {
          ...this.getGananciasCoinsRangoEvaluaciones,
          r_50_59: {
            cant: r_50_59,
            ganTotal: this.configCoinsCanjes.rango_50_59 * r_50_59,
          },
          r_60_74: {
            cant: r_60_74,
            ganTotal: this.configCoinsCanjes.rango_60_74 * r_60_74,
          },
          r_75_84: {
            cant: r_75_84,
            ganTotal: this.configCoinsCanjes.rango_75_84 * r_75_84,
          },
          r_85_94: {
            cant: r_85_94,
            ganTotal: this.configCoinsCanjes.rango_85_94 * r_85_94,
          },
          r_95_100: {
            cant: r_95_100,
            ganTotal: this.configCoinsCanjes.rango_95_100 * r_95_100,
          },
        };

        // console.log("newDataRanges", newDataRanges);
        this.updateRangoEvaluacionesCoins(newDataRanges);

        if (r_50_59 !== 0) {
          total += this.configCoinsCanjes.rango_50_59 * r_50_59;
        }
        if (r_60_74 !== 0) {
          total += this.configCoinsCanjes.rango_60_74 * r_60_74;
        }
        if (r_75_84 !== 0) {
          total += this.configCoinsCanjes.rango_75_84 * r_75_84;
        }
        if (r_85_94 !== 0) {
          total += this.configCoinsCanjes.rango_85_94 * r_85_94;
        }
        if (r_95_100 !== 0) {
          total += this.configCoinsCanjes.rango_95_100 * r_95_100;
        }
      }

      // console.log(total);

      return total;
    },
    async getPersonaUbicacion(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        // console.log("persona ubicacion...", res);
        if (res.cargo) {
          this.personaUbicacion = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getProgresoHojaDeVida(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `HojaDeVidaProgreso/${personaId}`,
        });
        // console.log("progresoHojaDeVida------------>", res);
        if (res !== null) {
          this.progresoHojaDeVida = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getListRangosEvaluacionesPersona() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "EstadisticasEvaluacionesEmpresaV2/ListRangosEvaluacionesPersona/" +
            this.usuario.id,
        });
        // console.log("list rangos evaluaciones persona...", res);
        if (res.success && res.data.length > 0) {
          const cantidadesPorRango = {
            r_50_59: 0,
            r_60_74: 0,
            r_75_84: 0,
            r_85_94: 0,
            r_95_100: 0,
          };

          res.data.forEach((evaluacion) => {
            if (evaluacion.rango) {
              if (cantidadesPorRango[evaluacion.rango]) {
                cantidadesPorRango[evaluacion.rango] += evaluacion.cantidad;
              } else {
                cantidadesPorRango[evaluacion.rango] = evaluacion.cantidad;
              }
            }
          });

          // console.log(cantidadesPorRango);

          this.listRangosEvaluaciones = {
            empresaId: res.data[0].empresaId,
            nombre_empresa: res.data[0].nombre_empresa,
            evaluadoId: res.data[0].evaluadoId,
            nombreEvaluado: res.data[0].nombreEvaluado,
            ...cantidadesPorRango,
          };

          // console.log(this.listRangosEvaluaciones);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getGeneralConfigCoinsCanjesByEmpresa(empresaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ConfigCoinsCanjes/GetGeneralByEmpresa/${empresaId}`,
        });
        // console.log("general ConfigCoinsCanjes...", res);
        if (res.success && res.data !== null) {
          this.updateConfigCoinsCanjes({
            ...res.data,
            areaIds:
              res.data.areaIds !== null ? res.data.areaIds.split(",") : [],
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getConfigCoinsCanjesByEmpresa(empresaId, areaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ConfigCoinsCanjes/GetByEmpresa/${empresaId}/${areaId}`,
        });
        // console.log("ConfigCoinsCanjes...", res);
        if (res.success && res.data !== null) {
          this.updateConfigCoinsCanjes({
            ...res.data,
            areaIds:
              res.data.areaIds !== null ? res.data.areaIds.split(",") : [],
          });
        } else {
          this.getGeneralConfigCoinsCanjesByEmpresa(empresaId);
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getListLegoAssignmentResponsibilityByPersona() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "Lego/ListLegoAssignmentResponsibilityByPersona/" + this.usuario.id,
        });
        // console.log("ListLegoAssignmentResponsibilityByPersona...", res);
        if (res.length > 0) {
          this.listTask = res;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getCanjesLog() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CanjesMarketLog/ListByPersona/" + this.usuario.id,
        });
        // console.log("list canjes persona...", res);
        if (res.success) {
          this.listCanjes = res.data;
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    showModal() {
      //this.$store.commit('modalShowRefresh', false)
      // console.log('modalShowRefresh', this.$store.state.modalShow)
      this.$store.commit("modalShowRefresh", true);
      //console.log('modalShowRefresh', this.$store.state.modalShow)
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaTicket/GetOpenTickets",
        });
        // console.log("tickets... ", res);
        if (res.length > 0) {
          if (this.usuario.rol.nivel == 4) {
            this.listaTickets = res.filter(
              (x) =>
                (x.paraId == null || x.paraId == this.usuario.id) &&
                x.estado == 1
            );
          } else {
            this.listaTickets = res.filter(
              (x) => x.paraId == this.usuario.id && x.estado == 1
            );
          }
        } else {
          this.listaTickets = [];
        }
      } catch (error) {
        this.listaTickets = [];
        console.log("err", error);
      }
    },
  },
};
</script>
<style>
.iq-navbar-logo {
  flex-shrink: 0;
}

/* .movil-navbar-item {
  margin-right: -30px !important;
} */

@media only screen and (min-width: 1300px) {
  .iq-navbar-logo > a {
    display: flex !important;
  }
}

@media (max-width: 767px) {
  .movil-navbar-item {
    margin-right: -30px !important;
  }
}
</style>
