<script>
import HelexiumLoader from "./HelexiumLoader.vue";

export default {
  name: "modalIframe",
  components: {
    HelexiumLoader,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    likeElement: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    iframeLoaded: {
      inserted: function (el, _, vnode) {
        el.addEventListener("load", function () {
          vnode.context._data.isLoaded = true;
        });
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
};
</script>

<template>
  <div @click="close" class="modalContainer rounded" :class="{ likeElement: likeElement }">
    <header style="width: 100%" @click.stop v-if="showHeader">
      <div style="width: 80%; margin: 1rem auto">
        <slot name="header">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <slot name="header-left"></slot>
            </div>
            <button
              class="btn btn-danger py-0 px-2"
              style="font-size: 1.05rem"
              @click="close"
            >
              &times;
            </button>
          </div>
        </slot>
      </div>
    </header>

    <iframe
      v-show="isLoaded"
      v-iframe-loaded
      :src="url"
      frameborder="0"
      allowfullscreen
      class="rounded"
      :class="{ likeElement: likeElement }"
    ></iframe>
    <helexium-loader v-show="!isLoaded" v-if="url != ''" class="loader" />
  </div>
</template>

<style scoped>
/* crea estilos para que el iframe se encuentre en la mitad de la pantalla con un fondo rgba(0,0,0,0.8) */
.modalContainer {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modalContainer.likeElement {
  position: absolute;
}

iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
}

iframe.likeElement {
  width: 100%;
  height: 100%;
}
</style>
